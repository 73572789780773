/******* Undoing Pantsuit ********/
/*
There are some additional styles still getting in the way.
*/

.reset-2023 {
  font-size: 16px; /* text-base */
}

.reset-2023 table {
  border-width: 0;
  border-color: transparent;
  font-family: unset;
  font-size: unset;
  margin: 0;
  overflow: unset;
  width: unset;
}

.reset-2023 table tr {
  border-width: 0;
  border-color: transparent;
  background-color: none;
}

.reset-2023 table th,
.reset-2023 table td {
  border-width: 0;
  border-color: transparent;
  display: revert;
  padding: 0;
  text-align: unset;
}

.reset-2023 label {
  margin: 0;
  font-size: unset;
}

.reset-2023 label span {
  font-weight: unset;
}

.reset-2023 input {
  background: unset;
  height: auto;
}

.reset-2023 hr {
  margin: 0;
}
