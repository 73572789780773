/* Root CSS file based on Tailwind, rather than the now-deprecated SCSS files */

@tailwind base;
@tailwind components;
@tailwind utilities;

/******* Undoing Preflight ********/
/*

These are some specific styles that we were relying on from the default
user-agent stylesheet that the Preflight resetter removed.

We went through almost all uses of <h*> tags to add bold and font size
explicitly, but there are enough <h4>s in .lbj-column-label containers that it
made sense to just CSS it.

*/
.lbj-column-label h4 {
  @apply font-bold;
}

hr {
  margin: 0.5em 0;
}

/* Overrides overflow-x: hidden from pantsuit css library to bring back the 
horizontal scroll bar */
html > body {
  overflow-x: auto;
}
